@media (max-width: 575px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    /* FOOTER */
    .footer {
        .newsletter {
            display: block !important;

            .content {
                h3 {
                    font-size: 22px !important;
                }

                p {
                    font-size: 14px !important;
                    line-height: 18px;
                }
            }

            .form {
                display: block !important;

                .form-group .form-control {
                    width: 100% !important;
                    margin-top: 10px;
                }

                .primary-btn {
                    margin-left: 0px !important;
                    width: 100%;
                    margin-top: 10px;
                    font-size: 14px !important;
                }
            }
        }

        .navigation-links {
            padding-bottom: 20px !important;

            .links-section {
                h5 {
                    margin-bottom: 10px !important;
                }

                .link {
                    margin-bottom: 5px !important;
                }
            }

            .footer-right {
                margin-top: 20px !important;
                display: block !important;

                .links-section {
                    margin-bottom: 20px !important;
                }
            }
        }

        .copyright-section {
            .section-details {
                display: block !important;

                .links {
                    margin-top: 5px;
                    justify-content: space-between !important;

                    .copyright-link {
                        margin-right: 0px !important;
                    }
                }

                h5 {
                    text-align: center;
                }

                p {
                    text-align: center;
                }
            }
        }
    }

    /* OUR TEAM */
    .our-team {
        .page-banner {
            padding-top: 100px !important;
            background-size: cover !important;

            .heading-overlay {
                padding: 20px 0px !important;

                h1 {
                    font-size: 30px !important;
                }
            }
        }

        .details {
            padding: 20px 0px !important;

            h2 {
                font-size: 18px !important;
                line-height: 22px !important;
            }
        }

        .our-people {
            p {
                font-size: 14px !important;
                margin-top: 10px !important;
            }
        }

        .about {
            padding-bottom: 0px !important;

            .about-img {
                img {
                    width: 100% !important;
                    height: 300px !important;
                }
            }

            .about-details {
                margin-top: 20px;

                .designation {
                    margin-top: 0px !important;
                }

                p {
                    margin-top: 15px !important;
                }
            }
        }

        .services-box {
            .full-width {
                margin-left: auto !important;
                margin-right: auto !important;
                width: 100% !important;
            }
        }
    }

    /* PROPERTY DETAILS */
    .property-details {
        .details-header {
            display: block !important;

            .address {
                h5 {
                    font-size: 16px !important;
                    text-align: center;
                }

                p {
                    text-align: center;
                }
            }

            .features {
                margin-top: 10px;

                .tile {
                    padding: 0px 10px !important;

                    .value {
                        white-space: nowrap !important;
                        font-size: 14px !important;
                    }

                    .label {
                        font-size: 12px !important;
                    }
                }

                .tile:first-child {
                    padding-left: 0px !important;
                    border-left: 0px solid transparent;
                }
            }
        }

        .page-left {
            .property-photos {
                .img-slider {
                    img {
                        height: 300px !important;
                    }
                }
            }

            .description .content-css {
                font-size: 14px !important;
            }

            .mortgage-calculator {
                form {
                    margin-top: 10px !important;
                }
            }

            .nearby-schools {
                overflow-x: auto;
                overflow-y: hidden;
            }
        }

        .page-right {
            margin-top: 15px;
        }
    }

    .find-home .property-listings .section-header {
        display: block !important;
    }

    .react-select .custom-select__control .custom-select__value-container .custom-select__single-value {
        font-size: 12px !important;
    }

    .find-home .property-listings .section-header .custom-select__control {
        width: 170px !important;
    }

    .find-home .property-listings .section-header p {
        margin-bottom: 5px !important;
    }

    .find-home .listings {
        height: auto !important;
    }

    .find-home .listings #map {
        height: 400px !important;
    }

    .listing-list .listing-list-body .property-images .swiper {
        width: 100px !important;
    }

    .listing-list .listing-list-body .property-images .property-img {
        width: 100px !important;
        height: 100px !important;
    }

    .listing-list .listing-list-body .property-details .top-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .top-section h5 {
        font-size: 16px !important;
        margin-bottom: 5px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .badge {
        padding: 5px 10px !important;
    }

    .listing-list .listing-list-body .property-details .top-section .images-number {
        padding: 2px 10px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section h5 {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p {
        margin-top: 0px !important;
    }

    .listing-list .listing-list-body .property-details .middle-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .middle-section p .mls-no {
        font-size: 14px !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section {
        display: block !important;
    }

    .listing-list .listing-list-body .property-details .bottom-section .other-details .details-box {
        margin-top: 5px !important;
        margin-left: 0px !important;
        margin-right: 25px !important;
    }

    .our-team .our-people h2 {
        font-size: 30px !important;
    }

    .our-team .about .about-details p {
        font-size: 15px !important;
    }

    .our-team .about .about-details h4 {
        font-size: 30px !important;
    }

    .homepage .banner .banner-text h1 {
        font-size: 28px !important;
        line-height: 35px !important;
    }

    .homepage .banner video {
        height: 400px !important;
    }

    .homepage .banner .bg-overlay {
        height: 400px !important;
    }

    .homepage .banner .banner-text h2 {
        font-size: 16px !important;
        margin-left: 10px;
        margin-right: 10px;
    }

    .homepage .banner .banner-text {
        top: 30% !important;
    }

    .prop-search {
        display: block !important;
    }

    .homepage .property-search .search-box .search-btn {
        margin-left: 0px !important;
        width: 100%;
        margin-top: 10px !important;
    }

    .homepage .about .about-img img {
        width: 350px !important;
        height: auto !important;
    }

    .homepage .about {
        padding: 30px 0px !important;
    }

    .homepage .about .about-details {
        margin-top: 15px !important;
    }

    .homepage .about .about-details h2 {
        font-size: 25px !important;
    }

    .homepage .about .about-details p {
        font-size: 15px !important;
    }

    .homepage .resources .resources-box {
        margin-bottom: 15px;
    }

    .homepage .resources {
        padding-bottom: 0px !important;
    }

    .homepage .real-estate-news {
        padding: 30px 0px !important;
    }

    .homepage .real-estate-news h2 {
        font-size: 25px !important;
        margin-bottom: 15px !important;
    }

    .homepage .real-estate-news .small-blog-box .blog-details h3 {
        font-size: 15px !important;
    }

    .homepage .real-estate-news .small-blog-box .blog-details p {
        font-size: 12px !important;
    }

    .homepage .real-estate-news .small-blog-box .blog-details .blog-tags .tag {
        font-size: 11px !important;
    }

    .homepage .real-estate-news .latest-blog {
        height: auto !important;
        margin-bottom: 20px !important;
    }

    .homepage .instagram-wall h3 {
        font-size: 25px !important;
    }

    .homepage .real-estate-news .latest-blog .blog-details h3 {
        font-size: 18px !important;
    }

    .homepage .instagram-wall img {
        margin-bottom: 15px;
    }

    .homepage .instagram-wall {
        padding: 30px 0px !important;
    }

    .homepage .get-in-touch .map-contact-form {
        position: relative !important;
        width: 100% !important;
    }

    .homepage .get-in-touch .maps-box-section {
        display: block !important;
    }

    .homepage .get-in-touch .map-section {
        height: 300px !important;
    }

    .our-agents .agents-list {
        padding: 30px 0px !important;
    }

    .our-agents .agents-list .alphabetical-list .alphabet-box .alphabet-box-cardbody .agent-card {
        height: auto !important;
        margin-bottom: 15px;
    }

    .our-agents .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .our-agents .page-banner .heading-overlay h1 {
        font-size: 30px !important;
    }

    .our-agents .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .agent-details {
        margin-top: 15px !important;
    }

    .agent-details .top-boxes {
        padding: 30px 0px 30px 0px !important;
    }

    .agent-details .top-boxes .agent-profile-box {
        height: auto !important;
        margin-bottom: 20px !important;
    }

    .agent-details .last-transactions h2 {
        font-size: 25px !important;
    }

    .agent-details .inquiry-form h2 {
        font-size: 20px !important;
        text-transform: uppercase;
    }

    .mortgage-calculator .calculator {
        padding: 30px 0px !important;
    }

    .mortgage-calculator .calculator .heading h1 {
        font-size: 25px !important;
    }

    .mortgage-calculator .calculator .heading p {
        font-size: 15px !important;
        line-height: 20px !important;
    }

    .calc-right {
        margin-top: 20px;
    }

    .mortgage-calculator .calculator .calculator-box .estimated-monthly-payment h4 {
        font-size: 25px !important;
    }

    .mortgage-calculator .calculator .calculator-box .estimated-monthly-payment {
        text-align: left !important;
    }

    .mortgage-calculator .calculator .calculator-box .legend:first-child {
        margin-top: 10px !important;
    }

    .home-evaluation .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .home-evaluation .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .home-evaluation .page-banner .heading-overlay h1 {
        font-size: 25px !important;
    }

    .home-evaluation .content {
        padding: 30px 0px !important;
    }

    .home-evaluation .content p {
        font-size: 14px !important;
        line-height: 22px !important;
    }

    .home-evaluation .content .content-list li {
        font-size: 14px !important;
    }

    .home-evaluation .home-evaluation-form .form-box {
        padding: 20px !important;
    }

    .home-evaluation .home-evaluation-form .form-box h2 {
        font-size: 25px !important;
    }

    .home-evaluation .home-evaluation-form .form-box h5 {
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .form-group .only-field {
        margin-top: 0px !important;
    }

    .become-an-agent .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .become-an-agent .page-banner .heading-overlay h1 {
        font-size: 25px !important;
    }

    .become-an-agent .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .become-an-agent .content {
        padding: 30px 0px !important;
    }

    .become-an-agent .content p {
        font-size: 15px !important;
        line-height: 24px !important;
    }

    .become-an-agent .content h2 {
        font-size: 23px !important;
    }

    .become-an-agent .content .content-list li {
        font-size: 15px !important;
    }

    .become-an-agent .content img {
        margin-bottom: 15px !important;
    }

    .about-us .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .about-us .page-banner .heading-overlay h1 {
        font-size: 25px !important;
    }

    .about-us .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .about-us .content {
        padding: 30px 0px !important;
    }

    .about-us .content p {
        font-size: 15px !important;
    }

    .about-us .content .bg-box {
        margin-bottom: 20px !important;
    }

    .about-us .content .bg-box h5 {
        font-size: 25px !important;
    }

    .about-us .content h5 {
        font-size: 25px !important;
    }

    .about-us .content .content-list li {
        font-size: 15px !important;
    }

    .events .upcoming-events {
        height: auto !important;
        margin-bottom: 20px;
        padding: 15px !important;
    }

    .buyer-overview .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .buyer-overview .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .buyer-overview .page-banner .heading-overlay h1 {
        font-size: 30px !important;
    }

    .buyer-overview .content {
        padding: 30px 0px !important;
    }

    .buyer-overview .content h2 {
        font-size: 25px !important;
    }

    .buyer-overview .content p {
        font-size: 15px !important;
    }

    .buyer-overview .content .content-list li {
        font-size: 15px !important;
    }

    .buyer-overview .find-real-estate-agent .box .overview-details {
        padding: 20px !important;
    }

    .buyer-overview .find-real-estate-agent .box .overview-details h2 {
        font-size: 25px !important;
    }

    .buyer-overview .find-real-estate-agent .box .overview-details p {
        font-size: 15px !important;
    }

    .buyer-overview .find-real-estate-agent .box img {
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 10px !important;
    }

    .seller-overview .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .seller-overview .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .seller-overview .page-banner .heading-overlay h1 {
        font-size: 25px !important;
    }

    .seller-overview .content p {
        font-size: 15px !important;
    }

    .seller-overview .find-real-estate-agent .box .overview-details {
        padding: 20px !important;
    }

    .seller-overview .find-real-estate-agent .box .overview-details h2 {
        font-size: 25px !important;
    }

    .seller-overview .find-real-estate-agent .box .overview-details p {
        font-size: 15px !important;
    }

    .seller-overview .find-real-estate-agent .box .overview-details .content-list li {
        font-size: 15px !important;
    }

    .seller-overview .find-real-estate-agent .box img {
        border-top-right-radius: 0px !important;
        border-bottom-left-radius: 10px !important;
    }

    .seller-overview .content h2 {
        font-size: 25px !important;
    }

    .seller-overview .content .content-list li {
        font-size: 15px !important;
    }

    .blog-detail {
        padding: 30px 0px !important;
    }

    .blog-detail .blog-detail-desc h1 {
        font-size: 30px !important;
    }

    .blog-detail .blog-detail-desc p {
        font-size: 15px !important;
    }

    .blog-detail .blog-detail-desc {
        padding-right: 0px !important;
    }

    .blog-detail .blog-detail-desc img {
        width: 100% !important;
    }

    .blog-detail .blog-detail-desc h5 {
        font-size: 25px !important;
    }

    .blog-detail .sidebar .you-might-like .blogs-list-view .blog-list-card h5 {
        font-size: 15px !important;
    }

    .blog-detail .sidebar .you-might-like .blogs-list-view .blog-list-card p {
        font-size: 13px !important;
    }

    .our-blogs .blogs-list .you-might-like .blogs-list-view .blog-list-card h5 {
        font-size: 15px !important;
    }

    .our-blogs .blogs-list .you-might-like .blogs-list-view .blog-list-card p {
        font-size: 13px !important;
    }

    .re-course .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .re-course .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .re-course .page-banner .heading-overlay h1 {
        font-size: 30px !important;
    }

    .re-course .content {
        padding: 30px 0px !important;
    }

    .re-course .content h2 {
        font-size: 25px !important;
    }

    .re-course .content p {
        font-size: 15px !important;
    }

    .re-course .content .requirement-box {
        margin-bottom: 15px !important;
    }

    .external-pages .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .external-pages .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .external-pages .page-banner .heading-overlay h1 {
        font-size: 30px !important;
    }

    .external-pages .content {
        padding: 30px 0px !important;
    }

    .find-home .property-listings .price-filters .react-select.sort-control {
        margin-top: 10px;
    }

    .find-home .property-listings .price-filters .filters-btn {
        margin-top: 10px;
        height: auto !important;
        justify-content: center;
    }

    .container-max-width {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .find-home .property-listings .section-header .switcher-buttons {
        margin-top: 10px;
        justify-content: center !important;
    }

    .find-home .property-listings .section-header .switcher-buttons p {
        margin-bottom: 0px !important;
    }

    .property-details .details-header .features {
        justify-content: center;
    }

    .our-blogs .page-banner {
        padding-top: 100px !important;
        background-size: cover !important;
    }

    .our-blogs .page-banner .heading-overlay {
        padding: 20px 0px !important;
    }

    .our-blogs .page-banner .heading-overlay h1 {
        font-size: 30px !important;
    }

    .container-full-width {
        padding: 0px 10px !important;
    }

    .services-box a {
        display: block !important;
        margin-bottom: 10px !important;

        .box {
            img {
                height: 260px !important;
                border-radius: 10px !important;
            }

            .details-overlay {
                border-radius: 10px;
            }
        }
    }

    .rm-padding {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .find-home .property-listings .section-body {
        height: auto !important;
    }

    .find-home .property-listings .section-body .blogs-pagination {
        padding-left: 0px !important;
    }

    .find-home .property-listings .section-body .blogs-pagination li a {
        font-size: 13px !important;
    }

    .find-home .property-listings .section-body .blogs-pagination li {
        padding: 5px;
    }

    .listing-list .listing-list-body .property-images .favorites .logo-badge p {
        font-size: 12px !important;
        white-space: nowrap;
    }

    .our-team .our-people .people-section img {
        height: 330px !important;
    }

    .become-an-agent .content .big-size {
        font-size: 23px !important;
    }

    .become-an-agent .content .bg-box {
        margin-bottom: 15px;
    }

    .listing-card .listing-cardbody .property-details .top-section h2 {
        font-size: 20px !important;
    }

    .listing-list .listing-list-body .property-details .top-section h2 {
        font-size: 18px !important;
        margin-bottom: 10px !important;
    }

    .our-team .our-offices .office-image {
        margin-bottom: 10px;
    }

    .blog-detail .blog-detail-desc .description-inner p h2 span {
        font-size: 20px !important;
    }
}

@media (max-width: 1024px) {
    .about-us .content .bg-box {
        margin-bottom: 15px;
    }

    .events .upcoming-events {
        margin-bottom: 20px;
    }

    .footer .navigation-links .company-section .external-logos {
        margin-bottom: 20px;
    }

    .blog-detail .sidebar .connect-with-agent .overlay h5 {
        font-size: 16px !important;
    }

    .become-an-agent .content img {
        margin-bottom: 20px;
    }

    .re-course .content .requirement-box {
        margin-bottom: 20px;
    }

    .form-group .status-radio {
        margin-left: 5px !important;
    }

    .form-group .status-radio:first-child {
        margin-left: 0px !important;
    }

    .find-home .property-listings .section-body {
        height: auto !important;
    }

    .property-details .page-left .mortgage-calculator .reset-btn {
        margin-left: 0px !important;
    }

    .services-box a .box .details-overlay {
        padding: 15px !important;
    }

    .services-box a .box .details-overlay .learn-btn p {
        font-size: 15px !important;
    }

    .homepage .about .about-img img {
        width: 100% !important;
    }

    .homepage .real-estate-news .latest-blog {
        margin-bottom: 15px;
    }

    .homepage .get-in-touch .form-section {
        margin-right: 20px;
    }

    .homepage .property-search {
        padding: 20px 0px !important;
    }

    .home-evaluation-page .form-card .form-cardbody {
        padding: 30px 25px !important;
    }

    .home-evaluation-page .form-card .form-cardbody h1 {
        font-size: 20px !important;
        line-height: 30px !important;
    }
}
