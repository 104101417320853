.about-us {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/about-banner-02.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 25px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .content {
        padding: 50px 0px;

        p {
            font-size: 16px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 0px;
        }

        h2 {
            margin-top: 25px;
            font-size: 30px;
            font-weight: 800;
            color: #CD5828;
            margin-bottom: 15px;
        }

        .content-list {
            margin: 20px 0px;

            li {
                font-size: 16px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
            }
        }

        .bg-box {
            position: relative;
            background-color: rgba($color: #CD5828, $alpha: 0.1);
            border-radius: 5px;
            padding: 25px;

            h2 {
                font-size: 30px;
                font-weight: 800;
                color: #CD5828;
                margin-bottom: 0px;
                margin-top: 0px !important;
            }

            p {
                margin-top: 10px;
                font-weight: 500;
                font-size: 15px;
            }

            img {
                position: absolute;
                width: 40px;
                height: auto;
                opacity: 0.1;
            }

            .left-quote {
                top: 10px;
                left: 10px;
            }

            .right-quote {
                bottom: 10px;
                right: 10px;
            }
        }
    }
}