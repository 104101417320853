.mortgage-calculator {
    .calculator {
        padding: 50px 0px;

        .heading {
            h1 {
                font-size: 35px;
                font-weight: 800;
                color: #323232;
                margin-bottom: 0px;
                text-transform: uppercase;
            }

            p {
                margin-top: 5px;
                font-weight: 500;
                font-size: 18px;
                color: #555555;
            }
        }

        .calculator-box {
            width: 100%;
            padding: 30px 25px;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);

            .legend {
                display: flex;
                align-items: flex-start;
                margin-bottom: 15px;

                .legend-color {
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;  
                    margin-top: 3px;
                }

                .legend-details {
                    margin-left: 10px;

                    h2 {
                        font-size: 15px;
                        font-weight: 700;
                        margin-bottom: 0px;
                    }

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 3px;
                    }
                }
            }

            .estimated-monthly-payment {
                text-align: center;

                h2 {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                .payment-monthly {
                    font-size: 24px;
                    margin-top: 5px;
                    font-weight: 700;
                }
            }

            .calc-btn {
                background-color: #CD5828;
                font-size: 14px;
                font-weight: 600;
                color: #FFF;
                border: 2px solid #CD5828;
                padding: 5px 20px;
                transition: background-color 0.3s ease-in-out;
            }

            .calc-btn:hover {
                background-color: #FFF;
                color: #CD5828;
            }

            .reset-btn {
                background-color: #F5F5F5;
                font-size: 14px;
                font-weight: 600;
                color: #323232;
                border: 2px solid #E5E5E5;
                padding: 5px 20px;
                transition: background-color 0.3s ease-in-out;
                margin-left: 10px;
            }

            .reset-btn:hover {
                background-color: #E5E5E5;
            }
        }
    }

    .how-to-use-calculator {
        padding-bottom: 50px;

        .calculator-box {
            h2 {
                font-size: 25px;
                font-weight: 800;
                margin-bottom: 0px;
                text-transform: uppercase;
            }

            .terminologies {
                margin-top: 20px;

                .info-box {
                    width: 100%;
                    background-color: #F5F5F5;
                    border-radius: 5px;
                    padding: 15px 20px;
                    margin-bottom: 20px;

                    h3 {
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 0px;
                    }

                    p {
                        font-size: 15px;
                        margin-top: 5px;
                        margin-bottom: 0px;
                        font-weight: 500;
                        color: #555555;
                    }
                }
            }
        }
    }
}