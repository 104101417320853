@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

 @import 'react-big-calendar/lib/sass/styles';
 @import 'react-big-calendar/lib/addons/dragAndDrop/styles';

/* RESPONSIVE SCSS */
@import './responsive.scss';

/* LAYOUT SCSS */
@import './layout/layout.scss';

/* COMPONENTS SCSS */
@import './components/listing-card.scss';
@import './components/property-box.scss';

/* PAGES SCSS */
@import './pages/for-buyers/find-a-home.scss';
@import './pages/home/index.scss';
@import './pages/our-team/index.scss';
@import './pages/buyer-overview/';
@import './pages/for-buyers/property-detail.scss';
@import './pages/our-agents/';
@import './pages/our-agents/agent-detail.scss';
@import './pages/mortgage-calculator/index.scss';
@import './pages/home-evaluation/index.scss';
@import './pages/become-an-agent/index.scss';
@import './pages/about-us/index.scss';
@import './pages/events/index.scss';
@import './pages/seller-overview/index.scss';
@import './pages/our-blogs/index.scss';
@import './pages/real-estate-practice-course/index.scss';
@import './pages/external-pages/index.scss';
@import './layout/loading.scss';
@import './pages/home-evaluation-page/index.scss';

body {
    font-family: 'Figtree', sans-serif;
    overflow: auto;
    overflow-x: hidden;
    max-width: 100%;
    position: relative;

    main {
        overflow-x: hidden;
    }
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1600px !important;
    padding-left: 100px;
    padding-right: 100px;
}

.container-full-width {
    max-width: 100% !important;
}

.container-max-width {
    max-width: 100% !important;
    padding-left: 60px;
    padding-right: 60px;
}

.primary-btn {
    background-color: #000;
    color: #FFF;
    font-weight: 500;
    padding: 8px 30px;
    border: 0px solid transparent;
    font-size: 14px;
}

.primary-btn:hover {
    background-color: #CD5828;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.p-relative {
    position: relative !important;
}

.react-select {
    .custom-select__control {
        border: 2px solid #E5E5E5;

        .custom-select__value-container {
            .custom-select__placeholder {
                font-size: 13px;
                font-weight: 400;
                color: grey;
            }

            .custom-select__single-value {
                font-weight: 500;
                font-size: 13px;
                color: #323232;
            }
        }

        .custom-select__indicators {
            .custom-select__indicator-separator {
                display: none;
            }

            .custom-select__indicator {
                padding-left: 0px;

                svg {
                    fill: #323232;
                    height: 16px;
                    width: 16px;
                }
            }
        }

        .custom-select__multi-value {
            background-color: #E5E5E5;
            padding: 3px 8px;
            border: 1px solid #E5E5E5;
            border-radius: 20px;
            font-size: 15px;
            font-weight: 600;

            .custom-select__multi-value__remove:hover {
                background-color: transparent;

                svg {
                    fill: #CD5828;
                }
            }
        }
    }

    .custom-select__control:hover {
        border: 2px solid #E5E5E5;
    }

    .custom-select__control.validation-border {
        border: 2px solid red !important;
    }
    .validation-border{
        border: 2px solid red !important;
    }

    .custom-select__control--is-focused {
        border: 2px solid #E5E5E5;
        box-shadow: none;
    }

    .custom-select__menu {
        z-index: 10;
        
        .custom-select__menu-list {
            padding: 5px;
            .custom-select__option {
                font-size: 13px;
                font-weight: 500;
                border-radius: 5px;
                cursor: pointer;
            }

            .custom-select__menuu-notice {
                font-size: 14px;
                font-weight: 600;
            }

            .custom-select__option--is-selected {
                background-color: #FFF;
                color: #323232;
            }

            .custom-select__option--is-focused {
                background-color: transparent;
                color: #323232;
            }

            .custom-select__option:hover {
                background-color: rgba($color: #CD5828, $alpha: 0.1);
                color: #CD5828;
            }
        }
    }

    .custom-select-two__control {
        width: 150px;
        border: 2px solid #E5E5E5;

        .custom-select-two__value-container {
            .custom-select-two__placeholder {
                font-size: 14px;
                font-weight: 500;
                color: #323232;
            }

            .custom-select-two__single-value {
                font-weight: 500;
                font-size: 14px;
                color: #323232;
            }
        }

        .custom-select-two__indicators {
            .custom-select-two__indicator-separator {
                display: none;
            }

            .custom-select-two__indicator {
                padding-left: 0px;

                svg {
                    fill: #323232;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    .custom-select-two__control--is-focused {
        border: 2px solid #E5E5E5;
        box-shadow: none;
    }

    .custom-select-two__menu {
        .custom-select-two__menu-list {
            .custom-select-two__option {
                font-size: 13px;
                font-weight: 500;
            }

            .custom-select-two__option--is-focused {
                background-color: rgba($color: #CD5828, $alpha: 0.1);
            }
        }
    }
}

.react-select.validation-border {
    .custom-select__control {
        border: 2px solid red !important;
    }
}

.form-group {
    margin-bottom: 15px;

    .dollar-sign {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 14px;
        font-weight: 600;
        color: #323232;
        margin-bottom: 0px;
    }

    .form-label {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;

        span {
            color: red;
        }
    }

    .form-control {
        width: 100%;
        border: 2px solid #E5E5E5;
        font-size: 14px;
        color: #323232;
        padding: 8px 15px;
    }

    .form-control.validation-border {
        border: 2px solid red !important;
    }

    .only-field {
        margin-top: 28px;
    }

    .calculator-input {
        padding-left: 30px;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .form-control::placeholder {
        font-size: 13px;
        font-weight: 400;
    }

    .start-dollar {
        position: absolute;
        top: 10px;
        left: 12px;
        color: #323232;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .end-percent {
        position: absolute;
        top: 10px;
        right: 12px;
        color: #323232;
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 14px;
    }

    .status-checkbox {
        min-height: auto;
        margin-bottom: 10px;

        .form-check-label {
            margin-left: 10px;
            font-weight: 400;
            font-size: 13px;
            margin-top: 5px;
            white-space: nowrap;
        }

        .form-check-input {
            height: 20px;
            width: 20px;
            border: 2px solid #E5E5E5;
        }

        .form-check-input:focus {
            box-shadow: none;
        }

        .form-check-input:checked {
            box-shadow: none;
        }
    }

    .status-radio {
        min-height: auto;
        margin-left: 20px;

        .form-check-label {
            margin-left: 5px;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
        }

        .form-check-input {
            height: 18px;
            width: 18px;
            border: 2px solid #E5E5E5;
        }

        .form-check-input:focus {
            box-shadow: none;
        }

        .form-check-input:checked {
            box-shadow: none;
        }
    }

    .options-radio {
        margin-top: 5px;
    }

    .status-radio:first-child {
        margin-left: 0px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.phone-number-select .PhoneInputCountry {
    border: 2px solid #E2E9E1;
    border-radius: 4px;
    padding: 15px;
    height: 40px;
    background-color: #FFF;
}

.phone-number-select.validation-border .PhoneInputCountry {
    border: 2px solid red !important;
}

.phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
    color: #465B52;
    font-size: 15px;
    margin-bottom: 10px;
}

.phone-number-select .PhoneInputInput {
    border: 2px solid #E2E9E1;
    border-radius: 4px;
    height: 40px;
    box-shadow: none;
    color: #161C27;
    font-size: 14px;
    padding: 6px 12px 6px 20px;
    width: 100%;
    font-weight: 500;
}

.phone-number-select.validation-border .PhoneInputInput {
    border: 2px solid red !important;
}

.phone-number-select .PhoneInputInput::placeholder {
    font-size: 12px;
    font-weight: 400;
}

.textarea-control {
    width: 100%;
    display: block;
    border: 2px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px 20px;
}

.textarea-control.validation-border {
    border: 2px solid red !important;
}

.textarea-control::placeholder {
    font-size: 13px;
    font-weight: 400;
}

.textarea-control:focus {
    outline: none;
}

/* SERVICES BOX */
.services-box {
    a {
        .box {
            position: relative;
            height: 100%;
    
            .box-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
    
            .details-overlay {
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.3);
                padding: 30px;
                display: flex;
                align-items: flex-end;
    
                .learn-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    background-color: #FFF;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    border-color: transparent;
                    padding: 10px 20px;
    
                    p {
                        margin-bottom: 0px;
                        font-weight: 600;
                        color: #323232;
                    }
                }
    
                .learn-btn:hover {
                    p {
                        color: #CD5828;
                    }
    
                    svg {
                        fill: #CD5828;
                    }
                }
            }
        }
    }
}

body::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: grey;
    outline: 1px solid grey;
    border-radius: 10px;
}

.rm-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.navbar-toggler {
    border: 0px solid transparent;

    .navbar-toggler-icon {
        height: 1.1em;
        width: 1.1em;
    }
}

.date-week-switcher {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .left-section {
        .previous-switcher {
            background-color: #FFF;
            border-radius: 50%;
            padding: 2px 8px;
            border: 1px solid #E5E5E5;
        }
    }

    .right-section {
        .next-switcher {
            background-color: #FFF;
            border-radius: 50%;
            padding: 2px 8px;
            border: 1px solid #E5E5E5;
        }
    }

    .center-section {
        padding: 0px 15px;

        h2 {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #323232;
        }

        p {
            color: #6C757D;
            font-weight: 500;
            font-size: 13px;
            margin-bottom: 0px;
        }
    }
}

.map-marker {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: rgba($color: #CD5828, $alpha: 0.3);
}

.marker-icon {
    position: absolute;
    top: -22px;
    left: -8px;
}

.appliances-css {
    span {
        overflow-wrap: break-word;
        font-size: 14px;
        font-weight: 600;
        color: #323232;
    }

    .show-more-less-clickable {
        font-size: 14px;
        font-weight: 600;
        color: #323232;
    }
}

.map-marker-custom {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dark-border {
        height: 20px;
        width: 20px;
        background-color: #D09E80;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .white-border {
            height: 10px;
            width: 10px;
            background-color: #FFF;
            border-radius: 50%;
        }
    }
}

.map-pin {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 2px solid #CD5828;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -23px;
    margin-top: -20px;

    img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
    }
}

.white-box {
    position: absolute;
    top: -50px;
    left: -10px;
    height: 60px;
    width: 60px;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba($color: #000000, $alpha: 0.25);
    padding: 5px;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}

.popover-property-details {
    display: flex;
    align-items: center;

    .property-img {
        img {
            height: 70px;
            width: 70px;
            border-radius: 5px;
            object-fit: cover;
        }
    }

    .property-details {
        margin-left: 10px;

        h5 {
            font-family: 'Figtree', sans-serif !important;
            font-size: 14px;
            font-weight: 700;
            color: #323232;
            margin-bottom: 0px;
        }

        .other-details {
            margin-top: 8px;

            p {
                font-family: 'Figtree', sans-serif;
                font-size: 13px;
                font-weight: 700;
                color: #555;
                margin-bottom: 0px;
            }

            .bed-bath-details {
                display: flex;
                align-items: center;
                margin-top: 5px;

                .bedbath {
                    font-family: 'Figtree', sans-serif;
                    font-size: 12px;
                    font-weight: 600;
                    color: #555;
                    margin-bottom: 0px;
                    border-right: 1px solid grey;
                    padding-right: 5px;
                }

                .bedbath:last-child {
                    padding-left: 5px;
                    border-right: 0px solid transparent;
                }
            }
        }
    }
}

.gm-style-iw-a {
    .gm-style-iw-t {
        .gm-style-iw {
            padding-left: 8px !important;
            padding-top: 8px !important;
            border-radius: 5px !important;

            .gm-style-iw-d {
                margin-bottom: -4px !important;
                margin-right: 12px !important;
            }
        }
    }
}

.combobbox-popover-styles {
    z-index: 100 !important;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 10px;

    ul {
        li {
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}

.no-properties-found-box {
    margin-top: 10px;
    background-color: #F5F5F5;
    padding: 20px;
    border-radius: 5px;

    h4 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0px;
        color: #323232;
    }

    p {
        margin-top: 5px;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0px;
    }
}

.locate-reset-btn {
    background-color: white;
    border: 0px solid #FFF;
    color: #000;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 13px;
    font-weight: 600;
}

.locate-reset-btn:hover {
    color: #CD5828;
    background-color: #FFF;
}

.Toastify__toast {
    .Toastify__toast-body {
        font-size: 15px;
        font-weight: 600;
        color: #323232;
        font-family: 'Figtree', sans-serif !important;
    }
}

.blogs-extra-content-css {
    span {
        font-size: 15px;
        font-weight: 500;
    }

    .show-more-less-clickable {
        display: none;
    }
}