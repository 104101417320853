.events {
    padding: 50px 0px;

    .upcoming-events {
        height: 745px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #F5F5F5;
        border: 1px solid #E5E5E5;
        padding: 25px;
        border-radius: 5px;

        h1 {
            font-size: 20px;
            font-weight: 800;
            margin-bottom: 0px;
        }

        .events-list {
            .event-box {
                border: 0px solid transparent;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                margin-top: 15px;

                .event-boxbody {
                    .time-details {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            margin-bottom: 0px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #555555;
                        }
                    }

                    .event-details {
                        margin-top: 15px;

                        h2 {
                            font-size: 17px;
                            font-weight: 700;
                            margin-bottom: 8px;
                            color: #323232;
                        }

                        .event-content {
                            span {
                                font-size: 15px;
                                color: #555555;
                                font-weight: 500;
                            }

                            .show-more-less-clickable {
                                font-size: 14px;
                                color: #323232;
                                font-weight: 600;
                            }
                        }

                        p {
                            font-size: 14px;
                            color: #555555;
                            margin-bottom: 0px;
                            font-weight: 500;
                        }

                        .rsvp-btn {
                            background-color: #FFF;
                            border: 2px solid #CD5828;
                            padding: 5px 20px;
                            font-size: 14px;
                            font-weight: 600;
                            border-radius: 5px;
                            color: #CD5828;
                            margin-top: 10px;
                        }

                        .rsvp-btn:hover {
                            background-color: #CD5828;
                            color: #FFF;
                        }
                    }
                }
            }

            .event-box:first-child {
                margin-bottom: 0px;
            }
        }

        .no-upcoming-events {
            background-color: #FFF;
            border-radius: 5px;
            margin-top: 10px;
            padding: 10px;

            p {
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
            }
        }
    }

    .upcoming-events::-webkit-scrollbar {
        width: 5px;
    }

    .upcoming-events::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    .upcoming-events::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        outline: 1px solid #E5E5E5;
        border-radius: 10px;
    }

    .container .row .upcoming-event-column {
        overflow-y: 'auto' !important;
        height: '100%' !important
    }

    .container .row  .calender-column{
        position:'sticky' !important;
        height:'100vh' !important;
        overflow-y:'auto' !important;
        top:'80px' !important
    }

    .events-calendar {
        height: 700px;
        max-height: 700px;

        .rbc-toolbar {
            .rbc-btn-group {
                button {
                    font-size: 13px;
                    font-weight: 600;
                    color: #323232;
                    border: 1px solid #E5E5E5;
                    padding: 8px 20px;
                }

                .rbc-active {
                    background-color: rgba($color: #CD5828, $alpha: 0.2);
                    box-shadow: none;
                }
            }

            .rbc-toolbar-label {
                font-size: 16px;
                font-weight: 700;
                text-align: end;
                padding-right: 0px;
            }
        }

        .rbc-month-view {
            border: 1px solid #E5E5E5;
            border-radius: 5px;

            .rbc-month-header {
                .rbc-header {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #E5E5E5;

                    span {
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
            }

            .rbc-month-row {
                .rbc-row-bg {
                    .rbc-off-range-bg {
                        background-color: #F5F5F5 !important;
                    }

                    .rbc-day-bg {
                        border-left: 1px solid #E5E5E5;
                    }

                    .rbc-day-bg:first-child {
                        border-left: 0px solid transparent;
                    }

                    .rbc-today {
                        background-color: rgba($color: #CD5828, $alpha: 0.2);
                    }
                }

                .rbc-row-content {
                    .rbc-row {
                        .rbc-date-cell {
                            .rbc-button-link {
                                font-size: 12px;
                                font-weight: 700;
                                color: #555555;
                            }
                        }

                        .rbc-off-range {
                            .rbc-button-link {
                                font-size: 12px;
                                font-weight: 400;
                                color: grey;
                            }
                        }

                        .rbc-event {
                            min-height: 30px;
                            background-color: transparent;
                            padding: 0px;
                        }

                        .rbc-event:focus {
                            outline: none;
                        }

                        .rbc-show-more {
                            margin-top: 3px;
                            color: #CD5828;
                            font-size: 11px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.calendar-event-box {
    cursor: pointer;
    background-color: #CD5828;
    border-radius: 2px;
    padding: 5px;

    p {
        margin-bottom: 0px;
        color: #FFF;
        font-weight: 600;
        font-size: 12px;
    }
}

.calendar-event-box.past {
    background-color: rgba($color: #CD5828, $alpha: 0.3);
}

.popover-css {
    z-index: 100;

    .event-box {
        position: relative;
        display: block;
        padding: 10px 15px;
        border-radius: 5px;
        width: 400px;
        max-width: 450px;
        border: 1px solid #E5E5E5;
        background-color: #FFF;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        .event-boxbody {
            .time-details {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin-bottom: 0px;
                    font-size: 13px;
                    font-weight: 600;
                    color: #555555;
                }
            }

            .event-details {
                margin-top: 15px;

                h2 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    color: #323232;
                }

                p {
                    font-size: 14px;
                    color: #555555;
                    margin-bottom: 0px;
                    font-weight: 500;
                }

                .rsvp-btn {
                    background-color: #FFF;
                    border: 2px solid #CD5828;
                    padding: 5px 20px;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 5px;
                    color: #CD5828;
                    margin-top: 10px;
                }

                .rsvp-btn:hover {
                    background-color: #CD5828;
                    color: #FFF;
                }
            }
        }

        .close-btn {
            position: absolute;
            top: -10px;
            right: -10px;
            width: max-content;
            border-radius: 50%;
            border: 1px solid #E5E5E5;
            padding: 2px 6px;
            background-color: #F5F5F5;
        }
    }
}

.rsvp-modal-footer {
    padding-top: 0px;
    border-top: 0px solid transparent;

    .cancel-button {
        background-color: #FFF;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 20px;
        color: #323232;
        border-color: #FFF;
    }

    .rsvp-button {
        background-color: #CD5828;
        border-color: #CD5828;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 20px;
    }
}

.all-events-header {
    border-bottom: 1px solid #E5E5E5;

    h5 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0px;
        color: #323232;
    }

    .btn-close {
       background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/0.6em auto no-repeat;
    }
}

.all-events-body {
    .event-box {
        position: relative;
        width: 100%;
        border: 1px solid #E5E5E5;
        background-color: #FFF;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        margin-top: 5px;

        .event-boxbody {
            .time-details {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin-bottom: 0px;
                    font-size: 13px;
                    font-weight: 600;
                    color: #555555;
                }
            }

            .event-details {
                margin-top: 15px;

                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    color: #323232;
                }

                .event-content {
                    span {
                        font-size: 15px;
                        color: #555555;
                        font-weight: 500;
                    }

                    .show-more-less-clickable {
                        font-size: 14px;
                        color: #323232;
                        font-weight: 600;
                    }
                }

                p {
                    font-size: 14px;
                    color: #555555;
                    margin-bottom: 0px;
                    font-weight: 500;
                }

                .rsvp-btn {
                    background-color: #FFF;
                    border: 2px solid #CD5828;
                    padding: 5px 20px;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 5px;
                    color: #CD5828;
                    margin-top: 10px;
                }

                .rsvp-btn:hover {
                    background-color: #CD5828;
                    color: #FFF;
                }
            }
        }
    }
}