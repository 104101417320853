/* CARD VIEW */
.listing-card {
    height: 100%;
    z-index: 0;
    transform: none !important;
    opacity: 1 !important;
    border: 1px solid #e5e5e5;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    .listing-cardbody {
        padding: 0px;

        .property-images {
            position: relative;

            .img-slider {
                width: 100% !important;
            }

            .property-img {
                width: 100%;
                height: 200px;
                object-fit: cover;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            .swiper {
                .swiper-button-next {
                    height: 25px !important;
                    width: 25px !important;
                    top: 55%;
                }

                .swiper-button-prev {
                    height: 25px !important;
                    width: 25px !important;
                    top: 55%;
                }

                .swiper-button-next:after,
                .swiper-button-prev:after {
                    background-color: #fff;
                    font-size: 9px !important;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 25px;
                    width: 25px;
                    font-weight: 700;
                    color: #000;
                }
            }

            .logo-badge {
                position: absolute;
                top: 10px;
                left: 10px;
                background-color: #cd5828;
                padding: 3px 10px;
                border-radius: 5px;
                z-index: 100;

                p {
                    font-size: 13px;
                    color: #fff;
                    margin-bottom: 0px;
                    font-weight: 600;
                }
            }

            .images-number {
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 5px;
                width: max-content;
                padding: 5px 10px;
                z-index: 100;
                display: flex;
                align-items: center;

                p {
                    color: #fff;
                    font-size: 14px;
                    margin-bottom: 0px;
                    font-weight: 600;
                    margin-left: 5px;
                }

                .loading-skeleton {
                    height: 15px;
                    width: 20px;
                    margin-left: 5px;
                }
            }

            .favorite-btn {
                position: absolute;
                top: 8px;
                right: 10px;
                background-color: #fff;
                width: max-content;
                z-index: 100;
                display: flex;
                align-items: center;
                border-radius: 50%;
                padding: 0px;
                border: 1px solid #cd5828;
                padding: 8px;
            }
        }

        .property-details {
            padding: 15px;

            h2 {
                font-size: 20px;
                margin-bottom: 0px;
                font-weight: 700;
                margin-top: 15px;
            }

            .top-section {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .loading-skeleton {
                    width: 120px;
                    height: 20px;
                }

                .badge-loading-skeleton {
                    height: 25px;
                    width: 90px;
                    background-color: rgba($color: #cd5828, $alpha: 0.1);
                }

                .badge {
                    background-color: rgba($color: #cd5828, $alpha: 0.1);
                    border-radius: 5px;
                    padding: 5px 10px;

                    p {
                        font-size: 12px;
                        color: #cd5828;
                        margin-bottom: 0px;
                        font-weight: 500;
                    }
                }

                .active-badge {
                    background-color: rgba($color: green, $alpha: 0.2);
                    padding: 3px 10px;
                    border-radius: 5px;

                    p {
                        font-size: 12px;
                        color: green;
                        margin-bottom: 0px;
                        font-weight: 500;
                    }
                }

                .closed-badge {
                    background-color: rgba($color: red, $alpha: 0.2);
                    padding: 3px 10px;
                    border-radius: 5px;

                    p {
                        font-size: 12px;
                        color: red;
                        margin-bottom: 0px;
                        font-weight: 500;
                    }
                }
            }

            .middle-section {
                margin-top: 15px;

                .loading-skeleton {
                    width: 150px;
                    height: 20px;
                }

                .city-loading-skeleton {
                    width: 100px;
                    height: 15px;
                    margin-top: 5px;
                }

                .mls-loading-skeleton {
                    width: 50px;
                    height: 15px;
                    margin-left: 5px;
                }

                h2 {
                    font-size: 16px;
                    color: #323232;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    color: grey;
                    font-weight: 400;
                    margin-top: 5px;
                    font-size: 14px;

                    .mls-no {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .listing-cardfooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        border-top: 1px solid #e5e5e5;
        padding: 10px 15px;

        .area-loading-skeleton {
            width: 50px;
            height: 20px;
        }

        .lot-loading-skeleton {
            width: 80px;
            height: 20px;
            margin-top: 5px;
        }

        p {
            margin-bottom: 0px;
            color: grey;
            font-weight: 400;
            font-size: 14px;

            span {
                font-size: 16px;
                color: #323232;
                font-weight: 700;
            }
        }

        .other-details {
            display: flex;
            align-items: center;

            .details-box {
                display: flex;
                align-items: center;
                margin-left: 20px;

                .bed-loading-skeleton {
                    width: 25px;
                    height: 15px;
                    margin-left: 5px;
                }

                img {
                    width: 20px;
                    height: auto;
                }

                p {
                    margin-left: 10px;
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #323232;
                }
            }
        }
    }
}

/* LIST VIEW */
.listing-list {
    border: 1px solid #e5e5e5;
    box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 100%;

    .listing-list-body {
        display: flex;
        align-items: center;

        .property-images {
            position: relative;

            .swiper {
                width: 150px;
            }

            .favorites {
                width: 100%;
                position: absolute;
                top: -5px;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;

                .logo-badge {
                    background-color: #cd5828;
                    padding: 5px 10px;
                    border-radius: 5px;

                    p {
                        font-size: 13px;
                        color: #fff;
                        margin-bottom: 0px;
                        font-weight: 700;
                    }
                }
            }

            .img-slider {
                width: 100% !important;
            }

            .property-img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 5px;
            }

            .swiper {
                .swiper-button-next {
                    height: 20px !important;
                    width: 20px !important;
                    top: 55%;
                }

                .swiper-button-prev {
                    height: 20px !important;
                    width: 20px !important;
                    top: 55%;
                }

                .swiper-button-next:after,
                .swiper-button-prev:after {
                    background-color: #fff;
                    font-size: 8px !important;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    font-weight: 700;
                    color: #000;
                }
            }

            .images-number {
                position: absolute;
                top: 10px;
                right: 55px;
                background-color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 5px;
                width: max-content;
                padding: 5px 10px;
                z-index: 100;
                display: flex;
                align-items: center;

                p {
                    color: #fff;
                    font-size: 14px;
                    margin-bottom: 0px;
                    font-weight: 600;
                    margin-left: 5px;
                }

                .skeleton-loading {
                    height: 10px;
                }
            }

            .favorite-btn {
                position: absolute;
                top: 8px;
                right: 10px;
                background-color: #fff;
                width: max-content;
                z-index: 100;
                display: flex;
                align-items: center;
                border-radius: 50%;
                padding: 0px;
                border: 1px solid #cd5828;
                padding: 8px;
            }

            .favorite-btn:hover {
                background-color: #cd5828;

                svg {
                    fill: #fff;
                }
            }
        }

        .property-details {
            width: 100%;
            margin-left: 20px;

            .top-section {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .loading-skeleton {
                    width: 130px;
                    height: 20px;
                }

                .badge-loading-skeleton {
                    height: 25px;
                    width: 90px;
                    background-color: rgba($color: #cd5828, $alpha: 0.1);
                }

                h2 {
                    font-size: 20px;
                    margin-bottom: 0px;
                    font-weight: 700;
                }

                .badge {
                    background-color: rgba($color: #cd5828, $alpha: 0.1);
                    border-radius: 5px;
                    padding: 10px 10px;

                    p {
                        color: #cd5828;
                        margin-bottom: 0px;
                        font-weight: 500;
                    }
                }

                .logo-badge {
                    background-color: #cd5828;
                    padding: 5px 10px;
                    border-radius: 5px;
                    margin-right: 10px;

                    p {
                        font-size: 13px;
                        color: #fff;
                        margin-bottom: 0px;
                        font-weight: 700;
                    }
                }

                .images-number {
                    background-color: rgba($color: #000000, $alpha: 0.5);
                    border-radius: 5px;
                    padding: 5px 10px;
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    p {
                        color: #fff;
                        font-size: 14px;
                        margin-bottom: 0px;
                        font-weight: 600;
                        margin-left: 5px;
                    }

                    .loading-skeleton {
                        width: 20px;
                        height: 15px;
                        margin-left: 5px;
                    }
                }

                .favorite-btn {
                    background-color: #fff;
                    width: max-content;
                    display: flex;
                    align-items: center;
                    border-radius: 50%;
                    padding: 0px;
                    border: 1px solid #cd5828;
                    padding: 8px;
                    margin-left: 10px;
                }

                .favorite-btn:hover {
                    background-color: #cd5828;

                    svg {
                        fill: #fff;
                    }
                }
            }

            .middle-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;

                .street-loading-skeleton {
                    width: 150px;
                    height: 20px;
                }

                .city-loading-skeleton {
                    width: 120px;
                    height: 15px;
                    margin-top: 5px;
                }

                h2 {
                    font-size: 16px;
                    color: #323232;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                p {
                    margin-bottom: 0px;
                    color: grey;
                    font-weight: 400;
                    margin-top: 2px;
                    font-size: 14px;

                    .mls-no {
                        font-size: 16px;
                    }
                }
            }

            .bottom-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;
                margin-top: 15px;

                .size-loading-skeleton {
                    width: 80px;
                    height: 20px;
                }

                .lot-loading-skeleton {
                    width: 100px;
                    height: 20px;
                    margin-left: 20px;
                }

                p {
                    margin-bottom: 0px;
                    color: grey;
                    font-weight: 400;
                    font-size: 14px;

                    span {
                        font-size: 16px;
                        color: #323232;
                        font-weight: 600;
                    }
                }

                .other-details {
                    display: flex;
                    align-items: center;

                    .bed-loading-skeleton {
                        width: 25px;
                        height: 15px;
                        margin-left: 5px;
                    }

                    .details-box {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;

                        img {
                            width: 20px;
                            height: auto;
                        }

                        p {
                            margin-left: 10px;
                            margin-bottom: 0px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #323232;
                        }
                    }
                }
            }
        }
    }
}
