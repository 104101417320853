.agent-details {
    .top-boxes {
        padding: 50px 0px 30px 0px;

        .agent-profile-box {
            width: 100%;
            height: 100%;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);

            .agent-image {
                width: 250px;
                height: 250px;
                object-fit: cover;
                border-radius: 50%;
                border: 2px solid #E5E5E5;
            }

            .agent-details {
                .details-header {
                    border-bottom: 2px solid #E5E5E5;
                    padding-bottom: 20px;

                    h1 {
                        font-size: 30px;
                        font-weight: 700;
                        margin-bottom: 0px;
                        color: #323232;
                    }

                    .social-links {
                        margin-top: 10px;

                        .social-icon {
                            border-radius: 50%;
                            padding: 0px;
                            background-color: #FFF;
                            border-color: #FFF;
                            margin-right: 15px;
                        }

                        .social-icon.facebook:hover {
                            svg {
                                fill: #1777F3;
                            }
                        }

                        .social-icon.instagram:hover {
                            svg {
                                fill: #BA2C7B;
                            }
                        }

                        .social-icon.linkedin:hover {
                            svg {
                                fill: #0077B7;
                            }
                        }

                        .social-icon.website:hover {
                            svg {
                                fill: #CD5828;
                            }
                        }
                    }
                }

                .details-body {
                    padding-top: 20px;

                    p {
                        font-size: 15px;
                        margin-bottom: 10px;
                        color: #555555;

                        span {
                            color: #000;
                            font-weight: 600;
                        }
                    }

                    .cta-btns {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;

                        .send-email {
                            background-color: #CD5828;
                            border-color: #CD5828;
                            font-size: 14px;
                            font-weight: 700;
                            padding: 8px 15px;
                            color: #FFF;
                        }

                        .call-now {
                            background-color: #FFF;
                            border-width: 2px;
                            border-color: #CD5828;
                            font-size: 14px;
                            font-weight: 700;
                            padding: 8px 15px;
                            color: #CD5828;
                            margin-left: 10px
                        }
                    }
                }
            }
        }
    }

    .inquiry-form {
        width: 100%;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);
        padding: 20px;

        h2 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        .submit-btn {
            width: 100%;
            background-color: #CD5828;
            font-size: 14px;
            font-weight: 700;
            color: #FFF;
            border-color: #CD5828;
        }
    }

    .about-agent {
        padding-bottom: 50px;

        .bio-box {
            width: 100%;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);

            .box-header {
                h2 {
                    font-size: 25px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                .underline {
                    height: 4px;
                    background-color: #000;
                    width: 60px;
                }
            }

            .box-body {
                margin-top: 15px;

                p {
                    margin-bottom: 0px;
                    font-size: 16px;
                    color: #555555;
                    font-weight: 500;
                }
            }
        }
    }

    .last-transactions {
        padding-bottom: 50px;

        h2 {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0px;
        }
    }
}