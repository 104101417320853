.our-agents {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/our-agents-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 30px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 700;
            }
        }
    }

    .description {
        padding: 50px 0px;

        p {
            color: #555555;
            font-size: 15px;
            font-weight: 500;
            margin-bottom: 0px;
        }
    }

    .agents-list {
        padding: 50px 0px;

        .clear-btn {
            width: 100%;
            background-color: #F5F5F5;
            border-color: #F5F5F5;
            font-size: 14px;
            color: #323232;
            font-weight: 600;
            padding: 10px 30px;
            margin-right: 10px;
            height: 40px;
            margin-bottom: 15px;
        }

        .search-btn {
            width: 100%;
            height: 40px;
            background-color: #CD5828;
            border-color: #CD5828;
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .more-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            p {
                font-size: 16px;
                color: grey;
                margin-bottom: 0px;

                span {
                    font-weight: 600;
                    color: #323232;
                }
            }
        }

        .alphabetical-list {
            margin-top: 25px;

            .alphabet-box {
                box-shadow: none;
                border: 1px solid #E5E5E5;

                .alphabet-box-cardheader {
                    background-color: #F5F5F5;
                    border-top: 1px solid #E5E5E5;
                    border-bottom: 1px solid #E5E5E5;
                    padding: 10px 20px;

                    h2 {
                        margin-bottom: 0px;
                        font-weight: 700;
                        font-size: 20px;
                        color: #323232;
                    }
                }

                .alphabet-box-cardbody {
                    .agent-card {
                        width: 100%;
                        height: 100%;
                        background-color: #FFF;
                        border-radius: 5px;
                        border: 1px solid #E5E5E5;
                        box-shadow: rgba(50, 50, 93, 0.15) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
                        text-align: center;

                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            border-radius: 50%;
                            border: 2px solid #E5E5E5;
                            margin-top: 15px;
                        }

                        .agent-details {
                            padding: 15px;
                
                            h2 {
                                font-size: 18px;
                                font-weight: 700;
                                color: #323232;
                                margin-bottom: 0px;
                            }

                            .telephone {
                                width: max-content;
                                margin-top: 10px;
                                text-decoration: none;
                                margin-right: 20px;

                                p {
                                    margin-left: 5px;
                                    margin-bottom: 0px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #323232;
                                }
                            }

                            .telephone:hover {
                                svg {
                                    fill: #CD5828;
                                }

                                p {
                                    color: #CD5828;
                                }
                            }

                            .dre-number {
                                margin-top: 10px;
                                text-decoration: none;

                                p {
                                    margin-left: 5px;
                                    margin-bottom: 0px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #323232;
                                }
                            }

                            .engagement-btns {
                                gap: 10px;
                            }

                            a {
                                width: 100%;
                                
                                .view-profile-btn {
                                    width: 100%;
                                    background-color: #CD5828;
                                    border: 2px solid #CD5828;
                                    margin-top: 15px;
                                    font-size: 14px;
                                    color: #FFF;
                                    font-weight: 600;
                                    transition: background-color 0.3s ease-in-out;
                                }
                            }

                            .contact-btn {
                                width: 100%;
                                background-color: #F5F5F5;
                                border: 2px solid #E5E5E5;
                                margin-top: 15px;
                                font-size: 14px;
                                color: #323232;
                                font-weight: 600;
                                transition: background-color 0.3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* MODAL CSS */
.contact-agent-header {
    h4 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    p {
        font-size: 15px;
        color: grey;
        font-weight: 400;
        margin-bottom: 0px;
    }
}

.contact-agent-cardfooter {
    .submit-btn {
        background-color: #CD5828;
        border-color: #CD5828;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 30px;
    }

    .cancel-btn {
        background-color: #F5F5F5;
        border-color: #F5F5F5;
        font-size: 14px;
        color: #323232;
        font-weight: 600;
        padding: 10px 30px;
        margin-right: 10px;
    }
}