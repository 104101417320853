/* HEADER */
.header {
    border-bottom: 1px solid #e5e5e5;

    .header-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px;

        .logo {
            width: 180px;
            height: auto;
        }

        .header-navbar {
            .main-menu {
                .menu-link {
                    text-transform: capitalize;
                    font-size: 14px;
                    font-weight: 600;
                    color: #323232;
                    margin-bottom: 0px;
                    padding: 0px 20px;
                    transition: color 0.2s ease-in-out;
                }

                .menu-link:hover {
                    color: #cd6858;
                }

                .dropdown {
                    .nav-link {
                        font-size: 14px;
                        font-weight: 600;
                        color: #323232;
                        margin-bottom: 0px;
                        padding: 0px 20px;
                    }

                    .dropdown-toggle::after {
                        margin-left: 5px;
                    }

                    .dropdown-menu {
                        border: 1px solid #e5e5e5;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-right: 60px;
                        padding-left: 20px;
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                        margin-top: 5px;

                        .dropdown-item {
                            font-size: 14px;
                            font-weight: 600;
                            color: #323232;
                            margin-bottom: 0px;
                            padding: 5px 0px;
                            text-align: left;
                        }

                        .dropdown-item:hover {
                            color: #cd5828;
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }

    .top {
        background-color: #cd5828;
        padding: 10px;

        .top-header {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;

            .brand-text {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0px;
            }

            .brand-link {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 0px;
                text-decoration: underline;
            }
        }
    }
}

/* FOOTER */
.footer {
    border-top: 2px solid #e5e5e5;

    /* NEWSLETTER */
    .newsletter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0px;

        .content {
            h3 {
                font-size: 30px;
                font-weight: 800;
                margin-bottom: 5px;
                margin-top: 0px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                color: #555555;
                margin-bottom: 0px;
            }
        }

        .form {
            display: flex;
            align-items: center;

            .form-group {
                margin-bottom: 0px;

                .form-control {
                    width: 350px;
                    height: auto;
                    margin-bottom: 0px;
                    padding: 8px 20px !important;
                    border: 1px solid #e5e5e5;
                }

                .form-control:focus {
                    box-shadow: none;
                    border: 1px solid #323232;
                }

                .form-control::placeholder {
                    font-size: 13px;
                }

                .form-control:active {
                    box-shadow: none;
                    border: 1px solid #323232;
                }
            }

            .primary-btn {
                height: 100%;
                margin-left: 10px;
            }
        }
    }

    /* NAVIGATION LINKS */
    .navigation-links {
        padding-bottom: 40px;

        .company-section {
            .logo {
                width: 300px;
                height: auto;
            }

            p {
                color: #555555;
                font-size: 15px;
                font-weight: 400;
                margin-top: 20px;
                margin-bottom: 20px;
                line-height: 20px;
            }

            .external-logos {
                display: flex;
                align-items: center;

                .external-logo-img {
                    width: 50px;
                    height: auto;
                    margin-right: 15px;
                }
            }
        }

        .links-section {
            h4 {
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .link {
                display: block;
                color: #555555;
                font-size: 14px;
                text-decoration: none;
                font-weight: 400;
                margin-bottom: 10px;
            }
        }

        .footer-right {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    /* COPYRIGHT SECTION */
    .copyright-section {
        padding: 20px 0px;
        background-color: rgba($color: #e5e5e5, $alpha: 0.3);
        border-top: 1px solid #e5e5e5;

        .section-details {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0px;
                color: #555555;
            }

            .links {
                display: flex;
                align-items: center;

                .copyright-link {
                    font-size: 14px;
                    color: #555555;
                    font-weight: 500;
                    display: block;
                    margin-right: 20px;
                    transition: color 0.2s ease-in;
                }

                .copyright-link:hover {
                    color: #cd5828;
                }

                .copyright-link:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}

.offcanvas-header {
    .logo {
        width: 200px;
        height: auto;
    }
}

.offcanvas-body {
    .main-menu {
        .nav-link {
            font-size: 15px;
            text-transform: capitalize;
            font-weight: 600;
        }

        .dropdown {
            .dropdown-menu {
                .dropdown-item {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }
}
