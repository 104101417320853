.homepage {

    .banner {
        position: relative;
        overflow-x: hidden;
        z-index: 0;

        video {
            width: 100%;
            height: 600px;
            object-fit: cover;
        }

        .bg-overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 600px;
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0);
        }

        .banner-text {
            width: 100%;
            position: absolute;
            top: 35%;
            left: 0;
            text-align: center;

            h1 {
                color: #FFF;
                font-size: 60px;
                font-weight: 800;
                margin-bottom: 0px;
                line-height: 65px;
            }

            h2 {
                font-weight: 600;
                font-size: 25px;
                margin-bottom: 0px;
                color: #FFF;
                margin-top: 15px;
            }
        }
    }

    .property-search {
        position: relative;
        padding: 20px 50px;
        z-index: 1;

        .search-box {
            width: 100%;
            box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
            padding: 20px;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            background-color: #FFF;
            margin-top: -10px;

            .search-btn {
                margin-left: 10px;
                background-color: #CD5828;
                font-size: 13px;
                font-weight: 700;
                height: 100%;
                padding: 8px 40px;
                border: 1px solid #CD5828;
                white-space: nowrap;
            }
        }
    }

    .about {
        padding: 60px 0px;
        background-image: linear-gradient(rgba(205, 88, 40, 0.1), #FFF);

        .about-img {
            text-align: center;

            img {
                width: 500px;
                height: auto;
                border-radius: 10px;
            }
        }

        .about-details {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h2 {
                font-size: 40px;
                font-weight: 800;
                color: #323232;
                margin-bottom: 0px;
            }

            p {
                color: #323232;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 20px;
            }

            .social-media {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid rgba($color: #323232, $alpha: 0.5);
                border-bottom: 1px solid rgba($color: #323232, $alpha: 0.5);
                padding: 10px 0px;
                margin-top: 15px;

                .social-media-btn {
                    display: flex;
                    align-items: center;
                    background-color: #FFF;
                    border-radius: 50%;
                    border: 2px solid #323232;
                    margin-right: 10px;
                    padding: 10px;
                }

                .social-media-btn:hover {
                    background-color: #CD5828;
                    border-color: #CD5828;

                    svg {
                        fill: #FFF;
                    }

                    p {
                        color: #FFF;
                    }
                }

                .learn-more-link {
                    text-decoration: none;
                    color: #323232;
                    font-weight: 500;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .resources {
        padding: 60px 0px;

        .resources-box {
            position: relative;

            img {
                width: 100%;
                height: 350px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                object-fit: cover;
            }

            .resources-overlay {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;

                .text-box {
                    background-color: #F5F5F5;
                    padding: 15px 25px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;

                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        margin-bottom: 0px;
                    }

                    p {
                        margin-top: 10px;
                        color: #555555;
                        margin-bottom: 0px;
                        font-size: 15px;
                        line-height: 20px;
                    }

                    .company-btn {
                        margin-top: 20px;
                        background-color: #CD5828;
                        border-color: #CD5828;
                        font-size: 14px;
                        font-weight: 600;
                        padding: 8px 20px;
                    }
                }
            }
        }
    }

    .real-estate-news {
        padding: 60px 0px;

        h2 {
            font-weight: 800;
            font-size: 40px;
            margin-bottom: 30px;
        }

        .latest-blog {
            border: 2px solid #E5E5E5;
            border-radius: 5px;
            padding: 15px;
            cursor: pointer;
            transition: box-shadow 0.5s ease-in-out;

            img {
                width: 100%;
                height: 350px;
                object-fit: cover;
                border-radius: 5px;
            }

            .blog-details {
                margin-top: 20px;

                .blog-tags {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 15px;
        
                    .tag {
                        background-color: rgba($color: #CD5828, $alpha: 0.2);
                        border-radius: 15px;
                        padding: 5px 15px;
                        font-size: 12px;
                        color: #CD5828;
                        font-weight: 500;
                        margin-right: 10px;
                    }
                }
        
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    margin-bottom: 10px;
                }
        
                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                    color: #555555;
                    font-weight: 500;
                }
            }
        }

        .latest-blog:hover {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }

        .small-blog-box {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            border: 2px solid #E5E5E5;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 10px;
            transition: box-shadow 0.3s ease-in-out;

            img {
                width: 120px;
                height: 120px;
                border-radius: 5px;
                object-fit: cover;
            }

            .blog-details {
                margin-left: 20px;

                .blog-tags {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 15px;

                    .tag {
                        background-color: rgba($color: #CD5828, $alpha: 0.2);
                        border-radius: 15px;
                        padding: 5px 15px;
                        font-size: 12px;
                        color: #CD5828;
                        font-weight: 500;
                        margin-right: 10px;
                    }
                }

                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                    color: #555555;
                    font-weight: 500;
                }
            }
        }

        .small-blog-box:hover {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        }
    }

    .instagram-wall {
        padding: 60px 0px;

        .instagram-heading-box {
            height: 100%;
            display: flex;
            align-items: center;

            h3 {
                font-size: 24px;
                font-weight: 800;
                text-align: left;
            }
        }

        .instagram-link {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            
            .bg-overlay {
                position: absolute;
                opacity: 0;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.5);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: opacity 0.3s ease-in-out;
            }

            .instagram-post {
                width: 100%;
                height: 100%;
            }

            .instagram-post video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            .instagram-post img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        .instagram-link:hover {
            .bg-overlay {
                opacity: 1;
            }
        }
    }

    .get-in-touch {
        padding-top: 60px;
        padding-bottom: 0px;

        .maps-box-section {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
        }

        .map-section {
            width: 100%;
            height: 600px;
            position: relative;
        }

        .form-section {
            background-color: #FFF;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
            padding: 20px;
            border: 1px solid #E5E5E5;

            h3 {
                font-weight: 800;
                margin-bottom: 0px;
                font-size: 30px;
            }

            .row {
                margin-top: 15px;
            }
        }

        .map-contact-form {
            max-width: 1600px !important;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
}