.external-pages {
    .privacy-policy {
        background-image: url('../../../assets/banners/privacy-policy-banner.webp');
    }

    .mls-disclaimer {
        background-image: url('../../../assets/banners/disclaimer-hero-scaled.webp');
    }

    .fair-housing-statement {
        background-image: url('../../../assets/banners/fair-housing-hero-scaled.webp');
    }

    .accessibility {
        background-image: url('../../../assets/banners/access-hero-scaled.webp');
    }

    .page-banner {
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 500px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 25px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 700;
            }
        }
    }

    .content {
        padding: 50px 0px;

        h5 {
            font-weight: 700;
            font-size: 18px;
            color: #323232;
            margin-bottom: 5px;
        }

        p {
            font-size: 15px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 0px;
        }

        .content-list {
            margin: 20px 0px;

            li {
                font-size: 15px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
                font-style: italic;
            }
        }
    }
}