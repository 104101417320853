.our-blogs {
  .page-banner {
    background-image: url("https://storage.googleapis.com/cdn-amberwoodre/images/blogs-banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 400px;

    .heading-overlay {
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      padding: 25px 0px;

      h1 {
        font-size: 40px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 800;
      }
    }
  }

  .blogs-list {
    padding: 50px 0px;

    .connect-with-agent {
      position: relative;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h5 {
          color: #fff;
          font-size: 20px;
          margin-bottom: 15px;
          font-weight: 600;
        }

        .get-started {
          background-color: #cd5828;
          font-size: 14px;
          font-weight: 600;
          border-color: #cd5828;
          padding: 8px 20px;
        }
      }
    }

    .blogs-pagination {
      margin-top: 20px;
      display: flex;
      align-items: center;
      list-style-type: none;
      justify-content: center;

      .previous,
      .next {
        background-color: #fff;
        border-radius: 50%;
        padding: 4px 10px;
        border: 1px solid #e5e5e5;

        a {
          text-decoration: none;
          color: #323232;
        }
      }

      .blog-pagination-btn {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: #FFF;
        border: 1px solid #E5E5E5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
      }

      li {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 1px solid #e5e5e5;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        cursor: pointer;

        a {
          font-size: 14px;
          font-weight: 600;
        }
      }

      li:hover {
        background-color: #cd5828;

        a {
          color: #fff;
        }
      }
      .active {
        background-color: #cd5828;

        a {
          color: #fff;
        }
      }
    }

    .blog-box {
      position: relative;
      height: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .details {
        padding: 15px;

        h5 {
          font-size: 16px;
          color: #cd5828;
          font-weight: 700;
          margin-bottom: 0px;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          color: #555555;
          margin-bottom: 0px;
          margin-top: 15px;
        }
      }
    }

    .new-one {
      img {
        height: 300px;
        border-radius: 5px;
      }

      .time-to-read {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 5px 10px;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 5px;

        p {
          margin-bottom: 0px;
          font-weight: 700;
          color: #323232;
          font-size: 12px;
        }

        .time-loading-skeleton {
          width: 30px;
          height: 15px;
        }
      }

      .details {
        width: 95%;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;

        .loading-skeleton {
          height: 15px;
          width: 60px;
          margin-bottom: 0px;
        }

        .title-loading-skeleton {
          height: 38px;
          width: 100%;
          margin-bottom: 0px;
        }

        span {
          color: grey;
          font-weight: 500;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }

    .you-might-like {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 20px;

      .box-header {
        border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
        padding-bottom: 10px;

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0px;
          color: #323232;
        }
      }

      .blogs-list-view {
        margin-top: 20px;

        .blog-list-card {
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }

          h5 {
            font-size: 16px;
            font-weight: 600;
            color: #cd5828;
            margin-bottom: 0px;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            color: #555555;
            margin-top: 5px;
            margin-bottom: 0px;
          }
        }

        .blog-list-card:hover {
          h5 {
            text-decoration: underline;
          }
        }
      }
    }

    .blog-categories {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 15px;

      .box-header {
        border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
        padding-bottom: 10px;

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0px;
          color: #323232;
        }
      }

      .box-body {
        padding-top: 15px;

        .category-link {
          display: block;
          font-size: 15px;
          color: #323232;
          font-weight: 500;
          text-decoration: none;
          margin-bottom: 10px;
        }

        .category-link:last-child {
          margin-bottom: 0px;
        }

        .category-link:hover {
          color: #cd5828;
        }
      }
    }

    .sidebar {
      position: sticky;
      top: 0;
    }
  }
  .filters-applied {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-right: 10px;
    }

    .applied-filters-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .applied-filter {
            display: flex;
            align-items: center;
            background-color: rgba($color: #CD5828, $alpha: 0.2);
            border-radius: 5px;
            padding: 5px 10px;
            margin-right: 5px;

            p {
                font-weight: 400;
                font-size: 12px;
                color: #CD5828;
            }

            .close-btn {
                min-height: auto;
                padding: 0px;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}
}

.blog-detail {
  padding: 50px 0px;

  .blog-detail-desc {
    padding-right: 30px;

    .description-inner {
      p {
        span {
          font-family: 'Figtree', sans-serif !important;
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 500 !important;
          color: #555555 !important;
        }

        a {
          span {
            color: #CD5828 !important;
          }
        }
      }

      p {
        h1 {
          span {
            display: block;
            font-size: 25px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }

        h2 {
          span {
            display: block;
            font-size: 25px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }

        h3 {
          span {
            display: block;
            font-size: 22px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }

        h4 {
          span {
            display: block;
            font-size: 20px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }

        h5 {
          span {
            display: block;
            font-size: 18px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }

        h6 {
          span {
            display: block;
            font-size: 16px !important;
            font-weight: 700 !important;
            color: #cd5828 !important;
            margin-bottom: 15px !important;
            margin-top: 20px !important;
            line-height: 36px !important;
          }
        }
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #555555;
    }

    h1 {
      font-weight: 800;
      color: #323232;
      font-size: 35px;
      margin-bottom: 20px;
    }

    img {
      width: 90%;
      height: auto;
      object-fit: cover;
      border-radius: 5px;
    }

    h5 {
      font-size: 30px;
      font-weight: 700;
      color: #cd5828;
      margin-bottom: 15px;
      margin-top: 20px;
    }

    .content-list {
      margin: 15px 0px;

      li {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #555555;
        margin-bottom: 10px;
      }
    }
  }

  .sidebar {
    position: sticky;
    top: 0;
    padding-top: 15px;

    .connect-with-agent {
      position: relative;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h5 {
          color: #fff;
          font-size: 20px;
          margin-bottom: 15px;
          font-weight: 600;
        }

        .get-started {
          background-color: #cd5828;
          font-size: 14px;
          font-weight: 600;
          border-color: #cd5828;
          padding: 8px 20px;
        }
      }
    }

    .you-might-like {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 20px;

      .box-header {
        border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
        padding-bottom: 10px;

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0px;
          color: #323232;
        }
      }

      .blogs-list-view {
        margin-top: 20px;

        .blog-list-card {
          margin-bottom: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }

          h5 {
            font-size: 16px;
            font-weight: 600;
            color: #cd5828;
            margin-bottom: 0px;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            color: #555555;
            margin-top: 5px;
            margin-bottom: 0px;
          }
        }

        .blog-list-card:hover {
          h5 {
            text-decoration: underline;
          }
        }
      }
    }

    .blog-categories {
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 15px;

      .box-header {
        border-bottom: 1px solid rgba($color: #323232, $alpha: 0.2);
        padding-bottom: 10px;

        h5 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0px;
          color: #323232;
        }
      }

      .box-body {
        padding-top: 15px;

        .category-link {
          display: block;
          font-size: 15px;
          color: #323232;
          font-weight: 500;
          text-decoration: none;
          margin-bottom: 10px;
        }

        .category-link:last-child {
          margin-bottom: 0px;
        }

        .category-link:hover {
          color: #cd5828;
        }
      }
    }
  }
}
