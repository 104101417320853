.home-evaluation {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/home-evaluation-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 25px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .content {
        padding: 50px 0px;
        p {
            font-size: 15px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 0px;
        }

        .content-list {
            margin: 20px 0px;

            li {
                font-size: 15px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
                font-style: italic;
            }
        }
    }

    .home-evaluation-form {
        padding-bottom: 50px;

        .form-box {
            padding: 30px 35px;
            border-radius: 5px;
            border: 1px solid #E5E5E5;
            box-shadow: 0px 2px 20px 0px rgba($color: #000000, $alpha: 0.1);

            h2 {
                color: #CD5828;
                font-weight: 800;
                font-size: 30px;
                margin-bottom: 0px;
                text-transform: uppercase;
            }

            p {
                font-weight: 500;
                font-size: 14px;
                margin-top: 5px;
                color: #555555;
                line-height: 20px;
            }

            .start-dollar {
                margin-top: 0px;
            }

            .evaluation-form {
                margin-top: 20px;

                .submit-btn {
                    font-size: 14px;
                    font-weight: 600;
                    background-color: #CD5828;
                    padding:5px 30px;
                    border-color: #CD5828;
                }
            }
        }
    }
}