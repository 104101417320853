.Loading{
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;

    .loading-container{
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 10px solid black;
        border-radius: 50%;
        border-right: none;
        border-top: none;
        animation: loading 2s linear infinite;
        position: absolute;
        top: 40%;
        left: 45%;
    }
    .house-icon{
        // position: absolute;
        // top: 50%;
        // left: 50%;
        scale: 2;
        animation: houseloading 2s linear infinite;
        color: #CD6858;
    }
    p{
        margin-top: 30px;
        font-size: 24px;
        font-weight: 600;
        color: #CD6858;
        // animation: houseloading 2s linear infinite;
    }

    .loading-space{
        // border: 1px solid black;
        position: relative;
        width: 100px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .loading-col{
            display: flex;
            justify-content: space-between;
            .loading-icon{
                font-size: xx-large;
            }
        }
        .loading-border{
            position: absolute;
            border: 1px solid black;
            height: 40px;
            width: 40px;
            border-radius: 2px;
            // transform: translate(-4px,-4px);
            animation: loadingborder 2s linear infinite;
            animation-timing-function: ease;
        }
    }
}

@keyframes loading {
    0%{
        transform: rotate(0deg);
        color: black;
    }
    100%{
        transform: rotate(360deg);
        color: #CD6858;
    }
}

@keyframes houseloading {
    0%{
        transform: scale(1);
        color: black;
    }
    30%{
        color: black;
    }
    50%{
        transform: scale(2);
        color: #CD6858;
    }
    70%{
        color: black;
    }
    100%{
        transform: scale(1);
        color: black;
    }
}

@keyframes loadingborder {
    0%{
        transform: translate(-4px,-4px);
    }
    20%{
        transform: translate(64px,-4px);
    }
    25%{
        transform: translate(64px,-4px);
    }
    45%{
        transform: translate(64px,64px);
    }
    50%{
        transform: translate(64px,64px);
    }
    70%{
        transform: translate(-4px,64px);
    }
    75%{
        transform: translate(-4px,64px);
    }
    95%{
        transform: translate(-4px,-4px);
    }
    100%{
        transform: translate(-4px,-4px);
    }
    
}