.our-team {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/office-banner-02.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;
        

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 30px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .details {
        padding: 40px 0px;

        h2 {
            text-align: center;
            font-weight: 700;
            font-size: 26px;
            line-height: 35px;
            color: #323232;
        }
    }

    .about {
        padding: 60px 0px;
        background-image: linear-gradient(rgba(205, 88, 40, 0.1), #FFF);

        .about-img {
            text-align: center;

            img {
                width: 500px;
                height: 500px;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .about-details {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h4 {
                font-size: 40px;
                font-weight: 800;
                color: #323232;
                margin-bottom: 0px;
                text-transform: uppercase;
            }

            .designation {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-top: 0px;
            }

            p {
                color: #323232;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 30px;
            }
        }
    }

    .our-people {
        padding-top: 60px;
        background-image: linear-gradient(rgba(205, 88, 40, 0.1), #FFF);

        h2 {
            text-align: center;
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 0px;
        }

        p {
            font-size: 18px;
            text-align: center;
            margin-top: 20px;
            font-weight: 500;
            color: #555555;
            font-style: italic;
        }

        .people-section {
            height: 100%;
            margin-top: 20px;
            border: 2px solid #E5E5E5;
            border-radius: 5px;
            padding: 15px;

            img {
                width: 100%;
                height: 380px;
                border-radius: 5px;
                background-color: #F5F5F5;
            }

            h3 {
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 0px;
                margin-top: 15px;
            }

            .designation {
                margin-bottom: 0px;
                margin-top: 3px;
                font-weight: 600;
                font-size: 16px;
                text-align: left;
            }

            .bio {
                margin-bottom: 0px;
                font-size: 15px;
                font-weight: 400;
                margin-top: 10px;
                text-align: left;
                font-style: normal;
            }
        }
    }

    .our-offices {
        padding: 60px 0px;

        h2 {
            text-align: center;
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 15px;
        }

        .office-image {
            cursor: pointer;
            position: relative;
            margin-bottom: 20px;

            img {
                width: 100%;
                height: auto;
                border-radius: 5px;
            }

            .watch-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;
                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .office-image:hover {
            .watch-overlay {
                opacity: 1;
            }
        }
    }
}