.find-home {
    /* PROPERTY LISTINGS */
    .property-listings {
        padding: 15px 0px;
        overflow-x: hidden;

        .search-location-box {
            position: relative;

            .search-dropdown {
                padding: 10px 15px;

                h4 {
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 0px;
                    color: #323232;
                    margin-left: 10px;
                }
            }

            .search-box {
                position: absolute;
                top: 0;
                right: 0;
                background-color: #cd5828;
                border: 2px solid #cd5828;
                height: 100%;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .price-filters {
            .react-select.sort-control {
                .custom-select__control {
                    padding-left: 0px !important;
                }
            }

            .custom-select__control {
                padding-left: 15px;
            }

            .filters-btn {
                width: 100%;
                height: 100%;
                background-color: #e5e5e5;
                border: 2px solid #e5e5e5;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    font-size: 14px;
                    margin-left: 5px;
                    color: #323232;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                svg {
                    flex-shrink: 0;
                }
            }

            .filters-btn:hover {
                background-color: #fff;
            }
        }

        .section-header {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 10px 15px;
            margin-bottom: 10px;

            p {
                font-size: 14px;
                color: grey;
                margin-bottom: 0px;
                font-weight: 400;

                span {
                    font-weight: 700;
                    color: #323232;
                }
            }

            .custom-select__control {
                width: 150px;
                border: 1px solid #e5e5e5;
            }

            .view-switcher {
                background-color: transparent;
                border: 0px solid transparent;
            }

            .view-switcher:hover {
                background-color: #e5e5e5;
            }

            .view-switcher.list-view {
                margin-right: 10px;
            }

            .view-switcher.grid-view {
                margin-right: 5px;
            }

            .view-switcher.active {
                background-color: #fff;
                border: 1px solid #e5e5e5;
            }

            .filters-btn {
                margin-left: 10px;
                height: 100%;
                background-color: #fff;
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                display: flex;
                align-items: center;

                p {
                    margin-left: 5px;
                    color: #323232;
                    font-weight: 600;
                }
            }

            .switcher-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                p {
                    margin-right: 15px;
                    margin-bottom: 0px;
                }
            }
        }

        .filters-applied {
            display: flex;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 15px;

            p {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
                margin-right: 10px;
            }

            .applied-filters-list {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                .applied-filter {
                    display: flex;
                    align-items: center;
                    background-color: rgba($color: #cd5828, $alpha: 0.2);
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-right: 5px;

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        color: #cd5828;
                    }

                    .close-btn {
                        min-height: auto;
                        padding: 0px;
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }

        .filters-header {
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 15px;
            margin-bottom: 15px;
            margin-top: 10px;

            .filters-footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;

                .filters-btn {
                    height: 100%;
                    background-color: #fff;
                    border: 1px solid #e5e5e5;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 14px;
                        color: #323232;
                        font-weight: 600;
                        margin-bottom: 0px;
                        text-transform: capitalize;
                    }
                }

                .property-number {
                    font-size: 14px;
                    color: grey;
                    margin-bottom: 0px;
                }

                .company-text {
                    font-size: 14px;
                    color: #323232;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                .apply-filters-btn {
                    background-color: #cd5828;
                    border-radius: 5px;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: capitalize;
                    border-color: #cd5828;
                }
            }

            .sub-heading {
                font-size: 15px;
                font-weight: 700;
                color: #323232;
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }

        .section-body {
            margin-top: 10px;
            height: calc(100vh - 250px);
            overflow: auto;
            overflow-x: hidden;

            .infinite-scroll-component__outerdiv {
                .infinite-scroll-component {
                    overflow-x: hidden !important;
                }
            }

            .blogs-pagination {
                margin-top: 20px;
                display: flex;
                align-items: center;
                list-style-type: none;
                justify-content: center;

                .previous,
                .next {
                    background-color: #fff;
                    border-radius: 50%;
                    padding: 4px 10px;
                    border: 1px solid #e5e5e5;

                    a {
                        text-decoration: none;
                        color: #323232;
                    }
                }

                .blog-pagination-btn {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    background-color: #fff;
                    border: 1px solid #e5e5e5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                }

                li {
                    height: 35px;
                    width: 35px;
                    border-radius: 50%;
                    border: 1px solid #e5e5e5;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    cursor: pointer;
                    padding: 5px;

                    a {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                li:hover {
                    background-color: #cd5828;

                    a {
                        color: #fff;
                    }
                }
                .active {
                    background-color: #cd5828;

                    a {
                        color: #fff;
                    }
                }
            }
        }

        .section-body::-webkit-scrollbar {
            display: none;
        }
    }

    /* LISTINGS MAP */
    .listings {
        padding: 15px 0px;
        height: calc(100vh - 100px);

        #map {
            border-radius: 5px;
            width: 100%;
            height: calc(100vh - 100px);
        }
    }
}
