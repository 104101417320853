.become-an-agent {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/become-an-agent-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 25px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .content {
        padding: 50px 0px;

        p {
            font-size: 16px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 0px;
        }

        h2 {
            margin-top: 25px;
            font-size: 30px;
            font-weight: 800;
            color: #CD5828;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        h3 {
            margin-top: 15px;
            font-size: 20px;
            font-weight: 700;
            color: #000;
            margin-bottom: 10px;
        }

        .big-size {
            margin-top: 25px;
            font-size: 30px;
            font-weight: 800;
            color: #CD5828;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 5px;
        }

        .content-list {
            margin: 20px 0px;

            li {
                font-size: 16px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
                line-height: 24px;

                span {
                    font-weight: 700;
                    color: #000;
                }
            }
        }

        .bg-box {
            background-color: #F5F5F5;
            border: 1px solid #E5E5E5;
            padding: 20px;
            border-radius: 5px;

            .grey-heading {
                font-size: 20px;
                font-weight: 700;
                color: #323232;
                margin-bottom: 10px;
                margin-top: 0px;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                color: #555555;
                margin-bottom: 5px;
            }

            .proceed-btn {
                background-color: #CD5828;
                border-color: #CD5828;
                font-size: 14px;
                font-weight: 600;
                margin-top: 15px;
                padding: 8px 30px;
            }
        }
    }
}