.seller-overview {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/seller-overview-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 30px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .content {
        padding: 50px 0px;

        h2 {
            font-size: 30px;
            font-weight: 800;
            text-transform: uppercase;
            color: #CD5828;
            margin-bottom: 15px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .content-list {
            margin: 20px 0px;

            li {
                list-style-type: disc;
                font-size: 16px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
            }
        }
    }

    .find-real-estate-agent {
        .box {
            background-color: rgba($color: #CD5828, $alpha: 0.1);
            border-radius: 10px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .overview-details {
                padding: 30px;

                h2 {
                    font-size: 30px;
                    font-weight: 800;
                    color: #CD5828;
                    text-transform: uppercase;
                }

                p {
                    margin-top: 20px;
                    color: #555555;
                    margin-bottom: 0px;
                    font-weight: 500;
                }

                .content-list {
                    margin: 20px 0px;

                    li {
                        list-style-type: disc;
                        font-size: 16px;
                        margin-bottom: 10px;
                        color: #555555;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}