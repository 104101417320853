.re-course {
    .page-banner {
        background-image: url('https://storage.googleapis.com/cdn-amberwoodre/images/real-estate-course-banner.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 400px;

        .heading-overlay {
            width: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            padding: 30px 0px;

            h1 {
                font-size: 40px;
                margin-bottom: 0px;
                color: #FFF;
                font-weight: 800;
            }
        }
    }

    .content {
        padding: 50px 0px;

        h2 {
            font-size: 30px;
            color: #CD5828;
            font-weight: 800;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            color: #555555;
            font-weight: 500;
            margin-bottom: 15px;

            a {
                color: #CD5828;
                font-weight: 700;
                text-decoration: none;
            }
        }

        .content-list {
            margin: 20px 0px;

            li {
                font-size: 15px;
                margin-bottom: 10px;
                color: #555555;
                font-weight: 500;
                font-style: italic;
            }
        }

        .requirement-box {
            border: 2px solid #E5E5E5;
            padding: 15px;
            border-radius: 10px;

            p {
                font-size: 16px;
                line-height: 24px;
                color: #555555;
                font-weight: 500;
                margin-bottom: 0px;
            }
        }
    }
}