.card-holder{
    // border: 1px solid black;
    border-radius: 10px;
    // padding: 10px;
    margin: 10px 0px;
    background-color: white;
    overflow: hidden;
    position: relative;

    .star{
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .card-image{
        width: 100%;
        height: auto;
    }

    .card-body{
        padding: 0px 10px 10px 10px;
    }

    .card-title{
        display: flex;
        justify-content: space-between;
        color: #4db7ac;
        align-items: center;
    }

    .for-sale{
        background-color: #dff7f9;
        padding: 5px 10px;
        border-radius: 2px;
    }

    .body-text{
        font-size: 1.5rem;
        font-weight: 500;
    }

    .location{
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        padding: 5px 0px;
        color: #b6b8be;
        font-weight: 500;
    }

    .card-footer{
        border-top: 1px solid black;
        padding: 10px 10px 0px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4db7ac;

        p{
            color: black;
            margin: 0px;
            padding: 0px 5px
        }

        .square-feet{
            display: flex;
            align-items: center;
        }

        .features{
            display: flex;
            align-items: center;
        }
    }
}